export default {
  parts: ['control', 'label', 'description', 'icon'],
  baseStyle: {
    control: {
      bg: 'white',
    },
  },
  sizes: {
    md: {
      control: {
        boxSize: '22px',
        borderRadius: '6px',
      },
      label: { fontSize: ['14px', '18px'] },
    },
  },
}
