import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

import layerStyles from './styles/layerStyles'
import textStyles from './styles/textStyles'
import colors from './styles/colors'
import components from './components'

export const breakpoints = {
  sm: '768px',
  md: '1280px',
  lg: '1680px',
  xl: '1920px',
  '2xl': '2600px',
}

const theme = extendTheme({
  colors,
  layerStyles,
  textStyles,
  components,
  breakpoints: createBreakpoints(breakpoints),
})

export default theme
