const textStyles = {
  itemTitle: {
    fontSize: ['18px', '24px'],
    color: 'primary.800',
  },
  itemDetail: {
    fontSize: ['16px', '18px'],
    color: 'font.1000',
    fontWeight: 300,
    textAlign: 'center',
  },
  h1: {
    fontSize: ['24px', '32px', '48px'],
    color: 'primary.800',
    fontWeight: 700,
    textAlign: 'center',
  },
}

export default textStyles
