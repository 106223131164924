import { useEffect } from 'react'
import { useRouter } from 'next/router'

type GAEventParams = {
  category: string
  action: string
  label?: string
  value?: string
}

type FBEventParams = {
  event: string
  value?: string
  currency?: string
}

type LogEventParams = {
  ga?: GAEventParams
  fb?: FBEventParams
}

type LogPageviewParams = {
  path: string
}

type GTMParams = {
  event: string
} & Partial<LogEventParams> &
  Partial<LogPageviewParams>

function isDataInvalid(data: GTMParams): boolean {
  return typeof data.event !== 'string'
}

export function gtmPush(data: GTMParams): void {
  if (!window || !window.dataLayer) return

  if (!isDataInvalid(data)) {
    window.dataLayer.push(data)
  }
}

export function logPageview(url: string): void {
  gtmPush({
    event: 'Track Page View',
    path: url,
  })
}

export function logEvent({ ga, fb }: LogEventParams): void {
  gtmPush({
    event: 'Track Custom Event',
    ga,
    fb,
  })
}

export function useGTMPageview(): void {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (
      url: string,
      { shallow }: { shallow: boolean },
    ) => {
      if (!shallow) {
        logPageview(url)
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])
}
