import { ReactNode, ReactElement } from 'react'
import { ChakraProvider } from '@chakra-ui/react'

import chakraTheme from './chakraTheme'

interface ThemeProviderProps {
  children: ReactNode
}

function AppThemeProvider(props: ThemeProviderProps): ReactElement {
  return (
    <ChakraProvider resetCSS theme={chakraTheme}>
      {props.children}
    </ChakraProvider>
  )
}

export default AppThemeProvider
