const colors = {
  primary: {
    25: '#f3f7fc',
    50: '#e2efff',
    100: '#b5d0fe',
    200: '#87b0f8',
    300: '#5991f2',
    400: '#2c71ed',
    500: '#104ebc', // main
    600: '#0944a6',
    700: '#033178',
    800: '#08275c', // darken
    900: '#001d4b',
    1000: '#000a1f',
  },
  secondary: {
    0: '#CCE8FF',
    50: '#CCE8FF',
    100: '#99D1FF',
    200: '#66BAFF',
    250: '#469EFF',
    270: '#424F7C',
    300: '#33A0FF',
    400: '#1A8CFF',
    500: '#0080FF',
    600: '#0069CC',
    700: '#004F99',
    800: '#003566',
    900: '#00254c',
    1000: '#001429',
  },
  tertiary: {
    0: '#FCECCF',
    50: '#FCECCF',
    100: '#F9D89F',
    200: '#F6C56F',
    300: '#F3B43F',
    400: '#ebb211',
    500: '#BE8F0E',
    600: '#8F690B',
    700: '#5F4407',
    800: '#473205',
    900: '#302103',
  },
  font: {
    0: '#ffffff',
    100: '#f6f6f6',
    200: '#ebebeb',
    300: '#e8e8e8',
    400: '#b2b2b2',
    500: '#a3a3a3',
    600: '#888888',
    650: '#6d6d6d',
    700: '#5E5E5E',
    800: '#3d3d3d',
    900: '#2b2b2b',
    1000: '#444444',
  },
  gray: {
    0: '#ffffff',
    50: '#F8F8F8',
    100: '#f6f6f6',
    120: '#F3F3F3',
    200: '#ebebeb',
    300: '#e9edf3',
    320: '#e6e6e6',
    350: '#d3d3d3', // border
    380: '#c1c1c1',
    400: '#b2b2b2',
    450: '#a4a4a4',
    500: '#a8a8a8',
    510: '#A6B2C4',
    600: '#888888',
    650: '#6d6d6d',
    700: '#5E5E5E',
    800: '#3d3d3d',
    850: '#333333',
    900: '#2b2b2b',
  },
  blue: {
    50: '#dbfdff',
    100: '#b6f0f6',
    200: '#8de5ed',
    300: '#64dae6',
    400: '#30ccdb',
    500: '#22b5c4',
    600: '#118d99',
    700: '#02666e',
    800: '#003d43',
    900: '#00161a',
  },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default colors as any
