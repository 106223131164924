const layerStyles = {
  background: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  actionable: {
    opacity: 1,
    cursor: 'pointer',
    transition: 'opacity 150ms',
    '&:hover': {
      opacity: 0.8,
    },
    '&:focus': {
      opacity: 0.65,
    },
    '&:active': {
      opacity: 0.5,
    },
  },
  landingContainer: {
    w: 'full',
    maxW: {
      base: '100%',
      md: '1113px',
    },
    mx: 'auto',
    px: {
      base: 7,
      sm: 6,
      md: 0,
    },
  },
  'blue-dot': {
    background: 'no-repeat center/contain url(/images/Landing/bg/blue-dot.svg)',
    pos: ['absolute'],
  },
  'yellow-dot-left': {
    background:
      'no-repeat center/contain url(/images/Landing/bg/yellow-dot-left.svg)',
    pos: ['absolute'],
  },
  'yellow-dot-right': {
    background:
      'no-repeat center/contain url(/images/Landing/bg/yellow-dot-right.svg)',
    pos: ['absolute'],
  },
  'blue-block': {
    pos: ['absolute'],
    bg: 'rgba(16, 78, 188, 0.2)',
  },
  relative: {
    pos: 'relative',
    maxH: '0px',
  },
  'desktop-only-block': {
    d: ['none', 'none', 'none', 'block'],
  },
  'desktop-only-flex': {
    d: ['none', 'none', 'none', 'flex'],
  },
  'desktop-only-grid': {
    d: ['none', 'none', 'none', 'grid'],
  },
  'no-desktop-block': {
    d: ['block', 'block', 'block', 'none'],
  },
  'no-desktop-flex': {
    d: ['flex', 'flex', 'flex', 'none'],
  },
  'no-desktop-grid': {
    d: ['grid', 'grid', 'grid', 'none'],
  },
  'tablet-and-higher-block': {
    d: ['none', 'none', 'block', 'block'],
  },
}

export default layerStyles
