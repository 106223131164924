declare global {
  export interface String {
    format: (...args: any[]) => string
    toTitleCase: () => string
  }

  export interface Number {
    clamp: (min: number, max: number) => number
  }
}

String.prototype.toTitleCase = function toTitleCase() {
  return this.replace(/\w\S*/g, function toTitleCase(txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

String.prototype.format = function format(...args: any[]) {
  return this.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] !== 'undefined' ? args[number] : match
  })
}

Number.prototype.clamp = function clamp(min, max) {
  return Math.min(Math.max(this.valueOf(), min), max)
}

export {}
