import { FunctionComponent, Fragment, ReactNode } from 'react'
import { AppProps } from 'next/app'
import { NextComponentType } from 'next'
import { DefaultSeo } from 'next-seo'
import { useRouter } from 'next/router'

import '@/lib/init'

import GlobalStyle from '@/styles/GlobalStyle'
import ThemeProvider from '@/styles/ThemeProvider'

import env from '@/lib/env'
import { useGTMPageview } from '@/lib/stats'

const Noop = ({ children }: { children: ReactNode }) => children

type PageComponentType = NextComponentType & {
  Layout?: FunctionComponent
}

const defaultTitle = 'PDPA E-Learning Course | LearnPDPA'
const defaultDescription =
  'เรียน PDPA ผ่านคอร์สออนไลน์ได้ทุกที่ ทุกเวลา ช่วยให้องค์กรและพนักงานเข้าใจ PDPA อย่างง่ายดาย ออกแบบหลักสูตรโดยผู้เชี่ยวชาญด้านกฎหมาย'

function MyApp(props: AppProps): ReactNode {
  const { pageProps: ssrPageProps } = props
  const Component: PageComponentType = props.Component
  const Layout = (Component.Layout || Noop) as FunctionComponent

  const router = useRouter()

  const url = `${env.APP_URL}${router.asPath}`

  useGTMPageview()

  return (
    <Fragment>
      <ThemeProvider>
        <GlobalStyle />
        <DefaultSeo
          defaultTitle={defaultTitle}
          titleTemplate="%s | LearnPDPA"
          description={defaultDescription}
          canonical={url}
          openGraph={{
            type: 'website',
            url,
            title: defaultTitle,
            description: defaultDescription,
            site_name: defaultTitle,
            images: [
              {
                url: `${env.APP_URL}/images/og.png`,
                width: 1200,
                height: 630,
                alt: defaultTitle,
              },
            ],
          }}
        />
        <Layout {...ssrPageProps}>
          <Component {...ssrPageProps} />
        </Layout>
      </ThemeProvider>
    </Fragment>
  )
}

export default MyApp
